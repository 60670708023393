<template>
  <section-related title="Popular in: " :items="places" prerender-list-name="places" :is-template="isTemplate">
    <router-link rel="nofollow" slot="item" slot-scope="{item}" :to="getToRoute(item)"> {{ item.pinyin }}</router-link>
  </section-related>
</template>

<script>
import SectionRelated from '@common/elements/layouts/SectionRelated';
import {getMigrationPlaceDetailRoute} from '@common/utils/utils.routes';

export default {
  props: {
    places: Array,
    isTemplate: Boolean,
  },
  methods: {
    getToRoute(item) {
      return getMigrationPlaceDetailRoute(item.id, item.pinyin);
    },
  },
  components: {SectionRelated},
};
</script>

<style scoped></style>
